import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/mdTypicalPage.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`List of Resources`}</h1>
    <h2>{`General`}</h2>
    <ul>
      <li parentName="ul">{`industry-wide`}
        <ul parentName="li">
          <li parentName="ul">{`ENR Top Lists of A/E/C firms by Revenue `}<a parentName="li" {...{
              "href": "https://www.enr.com/toplists",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://www.enr.com/toplists`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`codes and standards`}
        <ul parentName="li">
          <li parentName="ul">{`ADA Standards for Accessible Design `}<a parentName="li" {...{
              "href": "https://www.ada.gov/2010ADAstandards_index.htm",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://www.ada.gov/2010ADAstandards_index.htm`}</a></li>
          <li parentName="ul">{`National Fire Protection Association (NFPA) Codes & Standards are available to read online for free `}<a parentName="li" {...{
              "href": "https://www.nfpa.org/Codes-and-Standards/All-Codes-and-Standards/Codes-and-Standards",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://www.nfpa.org/Codes-and-Standards/All-Codes-and-Standards/Codes-and-Standards`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`firestopping`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.hilti.com/firestops",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://www.hilti.com/firestops`}</a>{` Firestopping systems (with Hilti products)`}</li>
        </ul>
      </li>
    </ul>
    <h2>{`Engineering`}</h2>
    <h3>{`Civil/Structural`}</h3>
    <ul>
      <li parentName="ul">{`loading`}
        <ul parentName="li">
          <li parentName="ul">{`ASCE7 wind speed based on site lat/long or address. `}<a parentName="li" {...{
              "href": "http://windspeed.atcouncil.org/",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`http://windspeed.atcouncil.org/`}</a>{` (note: this will soon by replaced by `}<a parentName="li" {...{
              "href": "https://hazards.atcouncil.org/",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://hazards.atcouncil.org/`}</a>{` )`}</li>
          <li parentName="ul">{`Seismic design values as referenced in ASCE7 / IBC. Published by the USGS. `}<a parentName="li" {...{
              "href": "https://earthquake.usgs.gov/designmaps/us/application.php",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://earthquake.usgs.gov/designmaps/us/application.php`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`steel`}
        <ul parentName="li">
          <li parentName="ul">{`AISC has released the companion resources for the 15th edition of the steel construction manual. These resources include design examples and tables to assist in designing members, connections and structural systems.  `}<a parentName="li" {...{
              "href": "https://www.aisc.org/publications/steel-construction-manual-resources/",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://www.aisc.org/publications/steel-construction-manual-resources/`}</a></li>
          <li parentName="ul">{`AISC (steel) posts their videos from past webinars and conferences at `}<a parentName="li" {...{
              "href": "http://www.asic.org/educationarchives",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`http://www.asic.org/educationarchives`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "http://www.boltcouncil.org/files/2014RCSCSpecification-withErrata.pdf",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`http://www.boltcouncil.org/files/2014RCSCSpecification-withErrata.pdf`}</a>{` RCSC Specificatin for Structural Joints Using High Strength Bolts (2014)`}</li>
          <li parentName="ul">{`Design Guide for Metal Cladding Fasteners from American Architectural Manufacturers Association (AAMA) which includes various screw capacities. `}<a parentName="li" {...{
              "href": "http://www.aamanet.org/event/2017Fall/TIR-A9-14.pdf",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`http://www.aamanet.org/event/2017Fall/TIR-A9-14.pdf`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`concrete`}
        <ul parentName="li">
          <li parentName="ul">{`UCLA's Design of Prestressed Concrete Lectures (Dirk Bondy) `}<a parentName="li" {...{
              "href": "https://www.youtube.com/playlist?list=PLXpvR5DF4MWaUYr0jYLs6XM5QcQRjVl3C",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://www.youtube.com/playlist?list=PLXpvR5DF4MWaUYr0jYLs6XM5QcQRjVl3C`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`general`}
        <ul parentName="li">
          <li parentName="ul">{`explaining engineering topics (Engineer4Free youtube channel)  `}<a parentName="li" {...{
              "href": "https://www.youtube.com/user/Engineer4Free/playlists",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://www.youtube.com/user/Engineer4Free/playlists`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`BIM/CAD/Software`}
        <ul parentName="li">
          <li parentName="ul">{`pyRevit is a library of Python based scripts for Revit. `}<a parentName="li" {...{
              "href": "http://eirannejad.github.io/pyRevit/",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`http://eirannejad.github.io/pyRevit/`}</a></li>
        </ul>
      </li>
    </ul>
    <h2>{`Construction`}</h2>
    <ul>
      <li parentName="ul">{`in the field`}
        <ul parentName="li">
          <li parentName="ul">{`How to Identify Rebar `}<a parentName="li" {...{
              "href": "http://www.crsi.org/index.cfm/steel/identification",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`http://www.crsi.org/index.cfm/steel/identification`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`equipment`}
        <ul parentName="li">
          <li parentName="ul">{`Caterpillar provides a book with specifications on each piece of equipment to help estimate performance and productivity. `}<a parentName="li" {...{
              "href": "https://www.warrencat.com/performance-handbook/",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://www.warrencat.com/performance-handbook/`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.jlg.com/en/equipment/equipment-selector",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://www.jlg.com/en/equipment/equipment-selector`}</a>{` JLG Aerial Lift Selection Guide`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.bigge.com/crane-charts/",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://www.bigge.com/crane-charts/`}</a>{` Crane Load Charts`}</li>
        </ul>
      </li>
      <li parentName="ul">{`safety`}
        <ul parentName="li">
          <li parentName="ul">{`OSHA safety regulations in construction `}<a parentName="li" {...{
              "href": "https://www.osha.gov/pls/oshaweb/owastand.display_standard_group?p_toc_level=1&p_part_number=1926",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://www.osha.gov/pls/oshaweb/owastand.display_standard_group?p_toc_level=1&p_part_number=1926`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`transportation / shipping`}
        <ul parentName="li">
          <li parentName="ul">{`A standard truck is ~50ft long with ~3ft overhang (depending on state regulations). For additional Oversize/Overweight Trucking Regulations (2012) information see `}<a parentName="li" {...{
              "href": "http://permits.scranet.org/wp-content/uploads/2012/10/SCRA_PM_1012.pdf",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`http://permits.scranet.org/wp-content/uploads/2012/10/SCRA_PM_1012.pdf`}</a></li>
          <li parentName="ul">{`Manual of Uniform Traffic Control Devices (MUTCD) `}<a parentName="li" {...{
              "href": "https://mutcd.fhwa.dot.gov/",
              "target": "_blank",
              "rel": "nofollow noopener noreferrer"
            }}>{`https://mutcd.fhwa.dot.gov/`}</a></li>
        </ul>
      </li>
    </ul>
    <h1>{`Podcasts`}</h1>
    <h2>{`General`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://aec-business.com/podcast-episodes/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`AEC Business`}</a></li>
    </ul>
    <h2>{`Engineering`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://engineeringmanagementinstitute.org/podcasts/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`The Engineering Career Coach`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://engineeringmanagementinstitute.org/podcasts/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`The Civil Engineering Podcast`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://engineered.network/pragmatic",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Pragmatic`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://engineered.network/causality",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Causality`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.aisc.org/education/continuingeducation/podcasts/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Steel Profiles (AISC Podcast)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://theengineeringcommons.com",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`The Engineering Commons`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://stemonfire.com",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`STEM on Fire`}</a></li>
    </ul>
    <h2>{`Construction`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://jbknowledge.com/category/podcasts",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`ConTechCrew`}</a>{`: Construction Technology`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.constructionleadingedge.com",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Construction Leading Edge`}</a>{`: Geared more toward owners of companies. Great info and stories though.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://protractorpodcast.com",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Protractor`}</a>{`: Geared more toward owning your own company.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://constructioncareerpodcast.com",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Construction Career Podcast`}</a>{`: answers the questions asked frequently by people looking to break into the Construction Industry`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      